import { TForm } from "./FormContext";

const taqeForm: {
  [
  key: string
  ]: {
    name: keyof TForm;
    title: string;
    placeholder: string;
    error?: string;
    mask?: string;
    options?: {
      value: string;
      key?: string;
    }[];
  };
} = {
  name: {
    name: 'nome',
    title: 'Nome Completo',
    placeholder: 'Digite seu nome',
  },
  phone: {
    name: 'telefone',
    title: 'Número do WhatsApp',
    error: 'Número inválido',
    mask: '(00) 00000-0000',
    placeholder: '(00) 00000-0000'
  },
  birthday: {
    name: 'data_nascimento',
    title: 'Data de Nascimento',
    placeholder: 'DD/MM/AAAA',
    error: 'Data de nascimento inválida',
  },
  cpf: {
    name: 'cpf',
    title: 'CPF',
    error: 'CPF inválido',
    mask: '000.000.000-00',
    placeholder: '000.000.000-00'
  },
  gender: {
    name: 'genero',
    title: 'Com qual gênero você se identifica?',
    options: [
      { value: 'Agênero' },
      { value: 'Gênero fluído' },
      { value: 'Homem Cis' },
      { value: 'Mulher Cis' },
      { value: 'Não binárie' },
      { value: 'Não declarar' },
    ],
    placeholder: 'Selecione uma opção'
  },
  gender_trans: {
    name: 'genero_trans',
    title: 'Você se identifica como uma pessoa trans? (se identifica com gênero diferente ao do nascimento)',
    options: [
      { value: 'Sim' },
      { value: 'Não' },
      { value: 'Prefiro Não Declarar' }
    ],
    placeholder: 'Selecione uma opção'
  },
  race: {
    name: 'raca',
    title: 'Pensando agora sobre a sua raça/etnia, como você se autodeclara de acordo com as seguintes opções?',
    options: [
      { value: 'Amarela' },
      { value: 'Branca' },
      { value: 'Indígena' },
      { value: 'Parda' },
      { value: 'Preta' },
      { value: 'Não declarar' },
      { value: 'Outra' }
    ],
    placeholder: 'Selecione uma opção'
  },
  which_race: {
    name: 'qual_raca',
    title: 'Se você respondeu “outra” na pergunta anterior descreva qual a raça que você se identifica',
    placeholder: 'Digite sua raça',
  },
  disability: {
    name: 'deficiencia',
    title: 'Você tem alguma deficiência permanente que limita a suas atividades do dia a dia?',
    options: [
      { value: 'Não, eu não tenho', key: 'none' },
      { value: 'Sim, eu tenho deficiência auditiva', key: 'Auditiva' },
      { value: 'Sim, eu tenho deficiência física', key: 'Física' },
      { value: 'Sim, eu tenho deficiência intelectual/cognitiva', key: 'Intelectual' },
      { value: 'Sim, eu tenho deficiência visual', key: 'Visual' },
      { value: 'Sim, eu tenho deficiência psicossocial', key: 'Psicossocial' },
    ],
    placeholder: 'Selecione uma opção'
  },
  school: {
    name: 'escolaridade',
    title: 'Qual seu grau de escolaridade?',
    options: [
      { value: 'Ensino Fundamental Cursando' },
      { value: 'Ensino Fundamental Completo' },
      { value: 'Ensino Médio Cursando' },
      { value: 'Ensino Médio Completo' },
      { value: 'Ensino Superior Cursando' },
      { value: 'Ensino Superior Completo' },
      { value: 'Pós-Graduação Cursando' },
      { value: 'Pós-Graduação Completo' }
    ],
    placeholder: 'Selecione uma opção'
  },
  state: {
    name: 'estado',
    title: 'Estado',
    placeholder: 'Selecione um estado'
  },
  city: {
    name: 'cidade',
    title: 'Cidade',
    placeholder: 'Selecione uma cidade'
  },
  neighborhood: {
    name: 'bairro',
    title: 'Bairro',
    placeholder: 'Digite seu bairro',
  },
  street: {
    name: 'rua',
    title: 'Rua',
    placeholder: 'Digite sua rua',
  },
  responsible_name: {
    name: 'nome_responsavel',
    title: 'Nome do responsável (apenas se for menor de idade)',
    placeholder: 'Digite o nome do responsável',
  },
  responsible_cpf: {
    name: 'cpf_responsavel',
    title: 'CPF do responsável (apenas se for menor de idade)',
    error: 'CPF inválido',
    mask: '000.000.000-00',
    placeholder: '000.000.000-00'
  },
  did_coletivo: {
    name: 'fez_coletivo',
    title: 'Mais de 250 mil jovens já participaram do Coletivo Jovem realizado presencialmente em parceria com organizações sociais em mais de 130 comunidades. Em 2020 lançamos a versão Online do Coletivo. Você já participou e concluiu algum dos dois programas anteriormente?',
    options: [
      { value: 'Sim, concluí o Coletivo Jovem, presencialmente' },
      { value: 'Sim, concluí o Coletivo Online, através do WhatsApp' },
      { value: 'Sim, concluí os dois programas' },
      { value: 'Não, nunca concluí o Coletivo' }
    ],
    placeholder: 'Selecione uma opção'
  },
  choose_coletivo: {
    name: 'escolha_coletivo',
    title: 'Atenção! Se você selecionou uma resposta afirmativa na pergunta anterior, saiba que o conteúdo do curso é o mesmo de outras edições do Coletivo Online. Você tem certeza que quer fazer novamente?',
    options: [
      { value: 'Sim' },
      { value: 'Não' }
    ],
    placeholder: 'Selecione uma opção'
  },
  knowing_by: {
    name: 'ficou_sabendo',
    title: 'Como você ficou sabendo do curso?',
    options: [
      { value: 'Whatsapp' },
      { value: 'Redes Sociais (Facebook, Instagram, LinkedIn)' },
      { value: 'Indicação de amigos/familiares (boca a boca ou via redes sociais)' },
      { value: 'Rádio, TV, Sites na internet, Imprensa' },
      { value: 'Educador do Coletivo Jovem' },
      { value: 'Encaminhado/indicado por uma instituição' },
      { value: 'Nenhuma das opções' }
    ],
    placeholder: 'Selecione uma opção'
  },
  zipCode: {
    name: 'cep',
    title: 'CEP',
    error: 'CEP inválido',
    mask: '00000-000',
    placeholder: '00000-000'
  },
  email: {
    name: 'email',
    title: 'E-mail',
    placeholder: 'Digite seu email',
    error: 'E-mail inválido'
  },
}

export default taqeForm;
