import React from "react";
import styles from "./css/Form.module.css";
import { useForm } from "../../Utils/FormContext";
import FormGroup from "./FormGroup";
import taqeForm from "../../Utils/taqeForm";
import { useGetCity, useGetState } from "../../Hooks/useStateCity";
import useFetch from "../../Hooks/useFetch";
import { FORM_CREATE_USER_POST } from "../../api";
import { addDoc, collection } from "firebase/firestore";
import { db } from "../../firebase";
import Modal from "../Modal";
import FormGroupMult from "./FormGroupMult";

declare global {
  interface Window {
    dataLayer: any[];
  }
}

const Form: React.FC = () => {
  const { form, setForm, blockSend, utmParams, setAnswered } = useForm();
  const [checkbox, setCheckbox] = React.useState(false);
  const [showErrorMessage, setShowErrorMessage] = React.useState(false);
  const [open, setOpen] = React.useState(false);

  const { request } = useFetch();

  const handleChangeInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    setForm((prev) => ({ ...prev, [event.target.id]: event.target.value }));
  }

  const handleChangeSelect = (event: React.ChangeEvent<HTMLSelectElement>) => {
    let newValue: any = event.target.value;

    if (event.target.id === 'deficiencia') {
      newValue = form.deficiencia || [];

      const index = newValue.indexOf(event.target.value);
    
      if (index !== -1) {
        newValue.splice(index, 1);
      } else {
        newValue.push(event.target.value);
      }
    }

    setForm((prev) => ({ ...prev, [event.target.id]: newValue }));
  }

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const [year, month, day] = String(form.data_nascimento).split('-');

    const sendForm = {
      lastName: form.nome,
      personEmail: form.email,
      personMobilePhone: form.telefone.replace(/\D/g, ''),
      cpf: form.cpf.replace(/\D/g, ''),
      personBirthdate: `${day}/${month}/${year}`,
      racaEtnia: form.raca,
      genderIdentify: form.genero,
      quaisDeficiencias: form.deficiencia?.filter((element) => element !== 'none'),
      endereco: {
        personMailingStreet: form.rua,
        bairro: form.bairro,
        personMailingCity: form.cidade,
        personMailingStateCode: form.estado,
        personMailingPostalCode: form.cep
      },
      nomeResponsavel: form.nome_responsavel ?? undefined,
      cpfResponsavel: form.cpf_responsavel ? form.cpf_responsavel.replace(/\D/g, '') : undefined,
    }

    const { options, url } = FORM_CREATE_USER_POST(sendForm);

    const { response, json } = await request(url, options);

    if (json?.success || String(json?.details).trim() === 'Jovem já está cadastrado.') {
      setAnswered(true);
    }

    await addDoc(collection(db, "forms"), {
      ...{ ...form, telefone: form.telefone.replace(/\D/g, '') },
      lys_response: {
        status: response?.status,
        response: json
      },
      utm: utmParams
    })

    try {
      window.dataLayer.push({
        'event': 'form_sucess_iccb',
        'formLocation': 'lp_iccb',
        'email': sendForm.personEmail,
        'telefone': sendForm.personMobilePhone,
        'utm': utmParams
      });
    } catch (err) {
      console.error('err', err);
    }

    if (json?.link) {
      localStorage.removeItem("form");
      localStorage.removeItem("params");
      window.location.href = json.link;
    } else {
      setShowErrorMessage(true);
    }
  }

  const isDate18orMoreYearsOld = (date: Date) => {
    const day = date.getDate();
    const month = date.getMonth();
    const year = date.getFullYear();

    return new Date(year + 18, month, day) <= new Date();
  }

  return (
    <div className={styles.background} id="form">
      <div className={styles.container}>
        <form className={styles.form} onSubmit={handleSubmit}>
          {/* nome */}
          <FormGroup
            name={taqeForm.name.name}
            type="text"
            value={form[taqeForm.name.name] || ""}
            title={taqeForm.name.title}
            placeholder={taqeForm.name.placeholder}
            handleChangeInput={handleChangeInput}
            errorMessage={taqeForm.name.error}
            mask={taqeForm.name.mask}
            required
          />

          {/* telefone */}
          <FormGroup
            name={taqeForm.phone.name}
            type="text"
            value={form[taqeForm.phone.name] || ""}
            title={taqeForm.phone.title}
            placeholder={taqeForm.phone.placeholder}
            handleChangeInput={handleChangeInput}
            errorMessage={taqeForm.phone.error}
            mask={taqeForm.phone.mask}
            required
          />

          {/* email */}
          <FormGroup
            name={taqeForm.email.name}
            type="text"
            value={form[taqeForm.email.name] || ""}
            title={taqeForm.email.title}
            placeholder={taqeForm.email.placeholder}
            handleChangeInput={handleChangeInput}
            errorMessage={taqeForm.email.error}
            required
          />

          {/* data_de_nascimento */}
          <FormGroup
            name={taqeForm.birthday.name}
            type="date"
            value={form[taqeForm.birthday.name] || ""}
            title={taqeForm.birthday.title}
            placeholder={taqeForm.birthday.placeholder}
            handleChangeInput={handleChangeInput}
            errorMessage={taqeForm.birthday.error}
            mask={taqeForm.birthday.mask}
            required
          />

          {/* cpf */}
          <FormGroup
            name={taqeForm.cpf.name}
            type="text"
            value={form[taqeForm.cpf.name] || ""}
            title={taqeForm.cpf.title}
            placeholder={taqeForm.cpf.placeholder}
            handleChangeInput={handleChangeInput}
            errorMessage={taqeForm.cpf.error}
            mask={taqeForm.cpf.mask}
            required
          />

          <p className={styles.form_paragraph}>
            As duas perguntas a seguir referem-se à sua autodeclaração de gênero. Prezamos pela diversidade e inclusão de todas as pessoas, pois acreditamos que existem muitas formas de se expressar. Sinta-se livre para selecionar a opção com a qual mais se identifica.
            E lembre-se de que você também pode escolher não declarar escolhendo a última opção de resposta.
          </p>

          {/* genero */}
          <FormGroup
            name={taqeForm.gender.name}
            type="option"
            value={form[taqeForm.gender.name] || ""}
            title={taqeForm.gender.title}
            placeholder={taqeForm.gender.placeholder}
            handleChangeSelect={handleChangeSelect}
            options={taqeForm.gender.options}
            required
          />

          {/* raca */}
          <FormGroup
            name={taqeForm.race.name}
            type="option"
            value={form[taqeForm.race.name] || ""}
            title={taqeForm.race.title}
            placeholder={taqeForm.race.placeholder}
            handleChangeSelect={handleChangeSelect}
            options={taqeForm.race.options}
            required
          />

          {/* disability */}
          <FormGroupMult
            name={taqeForm.disability.name}
            value={form[taqeForm.disability.name] || []}
            title={taqeForm.disability.title}
            placeholder={taqeForm.disability.placeholder}
            handleChangeSelect={handleChangeSelect}
            options={taqeForm.disability.options || []}
            required={false}
          />

          {/* school */}
          <FormGroup
            name={taqeForm.school.name}
            type="option"
            value={form[taqeForm.school.name] || ""}
            title={taqeForm.school.title}
            placeholder={taqeForm.school.placeholder}
            handleChangeSelect={handleChangeSelect}
            options={taqeForm.school.options}
            required
          />

          {/* state */}
          <FormGroup
            name={taqeForm.state.name}
            type="option"
            value={form[taqeForm.state.name] || ""}
            title={taqeForm.state.title}
            placeholder={taqeForm.state.placeholder}
            handleChangeSelect={handleChangeSelect}
            options={useGetState()}
            required
          />

          {/* city */}
          <FormGroup
            name={taqeForm.city.name}
            type="option"
            value={form[taqeForm.city.name] || ""}
            title={taqeForm.city.title}
            placeholder={form[taqeForm.state.name] ? taqeForm.city.placeholder : "Selecione um estado primeiro"}
            handleChangeSelect={handleChangeSelect}
            options={useGetCity(form[taqeForm.state.name] ? String(form[taqeForm.state.name]) : "")}
            disabled={form[taqeForm.state.name] ? false : true}
            required
          />

          {/* neighborhood */}
          <FormGroup
            name={taqeForm.neighborhood.name}
            type="text"
            value={form[taqeForm.neighborhood.name] || ""}
            title={taqeForm.neighborhood.title}
            placeholder={taqeForm.neighborhood.placeholder}
            handleChangeInput={handleChangeInput}
            required
          />

          {/* street */}
          <FormGroup
            name={taqeForm.street.name}
            type="text"
            value={form[taqeForm.street.name] || ""}
            title={taqeForm.street.title}
            placeholder={taqeForm.street.placeholder}
            handleChangeInput={handleChangeInput}
            required
          />

          {/* zipCode */}
          <FormGroup
            name={taqeForm.zipCode.name}
            type="text"
            value={form[taqeForm.zipCode.name] || ""}
            title={taqeForm.zipCode.title}
            placeholder={taqeForm.zipCode.placeholder}
            handleChangeInput={handleChangeInput}
            errorMessage={taqeForm.zipCode.error}
            mask={taqeForm.zipCode.mask}
            required
          />

          {/* responsible_name */}
          <FormGroup
            name={taqeForm.responsible_name.name}
            type="text"
            value={form[taqeForm.responsible_name.name] || ""}
            title={taqeForm.responsible_name.title}
            placeholder={taqeForm.responsible_name.placeholder}
            handleChangeInput={handleChangeInput}
            disabled={!form[taqeForm.birthday.name] || isDate18orMoreYearsOld(new Date(form[taqeForm.birthday.name] ? String(form[taqeForm.birthday.name]) : ""))}
            required={!isDate18orMoreYearsOld(new Date(form[taqeForm.birthday.name] ? String(form[taqeForm.birthday.name]) : ""))}
          />

          {/* responsible_cpf */}
          <FormGroup
            name={taqeForm.responsible_cpf.name}
            type="text"
            value={form[taqeForm.responsible_cpf.name] || ""}
            title={taqeForm.responsible_cpf.title}
            placeholder={taqeForm.responsible_cpf.placeholder}
            handleChangeInput={handleChangeInput}
            disabled={!form[taqeForm.birthday.name] || isDate18orMoreYearsOld(new Date(form[taqeForm.birthday.name] ? String(form[taqeForm.birthday.name]) : ""))}
            mask={taqeForm.responsible_cpf.mask}
            required={!isDate18orMoreYearsOld(new Date(form[taqeForm.birthday.name] ? String(form[taqeForm.birthday.name]) : ""))}
            errorMessage={taqeForm.responsible_cpf.error}
          />

          {/* knowing_by */}
          <FormGroup
            name={taqeForm.knowing_by.name}
            type="option"
            value={form[taqeForm.knowing_by.name] || ""}
            title={taqeForm.knowing_by.title}
            placeholder={taqeForm.knowing_by.placeholder}
            handleChangeSelect={handleChangeSelect}
            options={taqeForm.knowing_by.options}
            required
          />

          <label className={styles.term} htmlFor="terms">
            <input
              className={styles.term_checkbox}
              type="checkbox"
              id="terms"
              name="terms"
              checked={checkbox}
              onChange={() => {
                setCheckbox(!checkbox)
                // set date with time DD-MM-AAAA HH:MM:SS
                setForm(
                  (prev) => ({
                    ...prev,
                    data_termos: `${(new Date().toLocaleDateString("pt-BR"))} ${new Date().toLocaleTimeString("pt-BR")}`.replace(/\//g, "-")
                  })
                )
              }}
              required
            />
            <span className={styles.term_text}>
              Para participar do curso, é necessário ler, entender e aceitar os <button type="button" onClick={() => setOpen(true)}>termos de uso e imagem</button> do curso. Acesse os termos de uso na descrição do curso.
            </span>
          </label>

          <button className={styles.form_button} type="submit" disabled={blockSend}>ENVIAR</button>

          {showErrorMessage && <span className={styles.error_message}>Ops! Algo deu errado, tente novamente em instantes.</span>}
          {blockSend && <span className={styles.error_message}>Preencha todos os campos corretamente.</span>}
        </form>
      </div>

      {open && <Modal setIsOpen={setOpen} />}
    </div>
  );
};

export default Form;
